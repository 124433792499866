import React from 'react';
import appstore from '../../../appStore';
import { OptionalUI } from '../../../modules/Booking/OptionalParts/OptionalUI';
import { DialogKind } from '../../../modules/Dialog/DialogEntities';
import { Dispatch } from '../../../modules/Dispatch';
import { GeoPoint } from '../../../modules/Location/Entities';
import { BookingTrackingProps } from '../../../modules/MyBookings/UI/ExpandedSection';
import { BookingStatus } from '../../../Services/BookingEntities';
import { MapMarkers } from '../MapMarkers';
import './TaxiTrackingGoogleMap.scss';
import { MapPurpose } from './GoogleMapProps';
import { GoogleMapRaw } from './GoogleMapRaw';

/**
 * Google Map component optimised for tracking the taxi on a live booking.
 */
export const TaxiTrackingGoogleMap: React.FC<BookingTrackingProps> = (props) => {
    return (
        <div className="tracking-map-wrapper" onClick={OnMapClicked}>
            <GoogleMapRaw
                mapCenter={GetMapCenter()}
                zoom={15}
                vehicleLocation={GetTaxiMarkerLocation()}
                pickupLocation={GetPickupMarkerLocation()}
                dropoffLocation={GetDropoffMarkerLocation()}
                carNumber={props.BookingDetails.TrackingInfo.CarNumber}
                PickupMarker={MapMarkers.PickupLocation}
                PlannedRoute={null}
                Purpose={MapPurpose.TaxiTracking}
            />
        </div>
    );

    /** 
     *  Gets the vehicle location for the Taxi map marker. 
     *  It will be undefined (no marker) when there is no Taxi assigned or its location isn't known.
     */
    function GetTaxiMarkerLocation(): GeoPoint | undefined {

        const vehicleLocation = props.BookingDetails.TrackingInfo.VehicleLocation;
        if (!vehicleLocation) return undefined;

        const result: GeoPoint = {
            latitude: vehicleLocation.Latitude,
            longitude: vehicleLocation.Longitude,
        };

        return result;
    }

    /**
     *  Gets the location of the pickup address map marker.
     *  During taxi tracking, we only show this when the Taxi is on the way to the pickup (status 5, accepted).
     */
    function GetPickupMarkerLocation(): GeoPoint | undefined {

        // Accepted only
        const bookingStatus = props.BookingDetails.TrackingInfo.BookingStatusID;
        if (bookingStatus !== BookingStatus.Accepted) return undefined;

        // TODO: review this code. The original code included this exit condition
        // However, the typings say that PickupLocation can't be null / undefined.
        const location = props.BookingDetails.PickupLocation;
        if (!location) return undefined;

        // format conversion
        const result: GeoPoint = {
            latitude: location.Latitude,
            longitude: location.Longitude,
        };

        return result;
    }

    /**
     *  Gets the location of the dropoff address map marker.
     *  During taxi tracking, we only show this when the Taxi is on the way to the dropoff (status 6, picked up).
     */
    function GetDropoffMarkerLocation(): GeoPoint | undefined {

        // Picked Up only
        const bookingStatus = props.BookingDetails.TrackingInfo.BookingStatusID;
        if (bookingStatus !== BookingStatus.PickedUp) return undefined;

        // unspecified dropoff
        const location = props.BookingDetails.DropoffLocation;
        if (!location) return undefined;

        // format conversion
        const result: GeoPoint = {
            latitude: location.Latitude,
            longitude: location.Longitude,
        };

        return result;
    }

    /** Normally centered on the Taxi. */
    function GetMapCenter(): GeoPoint {

        const taxiLocation = GetTaxiMarkerLocation()
        if (taxiLocation) return taxiLocation;

        // fallback (not expected)
        return appstore.getState().location.reliableLocation.value.geoPoint;
    }

    /** The user clicks on the <div> which hosts the tracking map. */
    function OnMapClicked() {

        // only supported on desktop devices
        if (OptionalUI.MobileLayout(appstore.getState())) return;

        Dispatch.MyBookings.AddMapFocusedBookingID(props.BookingDetails.BookingID);
        Dispatch.Dialog.ShowDialog(DialogKind.TaxiTrackingMap);
    }
}