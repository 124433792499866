import React, { Component } from 'react';
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import './Credentials.scss';
import { Dispatch } from '../../Dispatch';
import { Credentials } from '../AuthEntities';
import { ConstructProfileName } from '../Login/CredentialHelper';
import { CredentialEmailState } from './ResponsiveEmailInput';
import { OutlinedTextField, CustomTextFieldProps } from "../../Booking/Widget/OutlinedTextField";
import { Config } from "../../../Config/Config";

interface PropsFromStore {
    Credentials: Credentials;

    /** Contact name from the booking form if populated. */
    BookingName?: string;
}

/**
 * Renders the profile name input in the new login/signup form.
 */
class ResponsiveNameInput extends Component<PropsFromStore, CredentialEmailState> {
    private inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: PropsFromStore) {
        super(props);
        this.inputRef = React.createRef();

        this.state = {
            message: null,
            hasInputValue: false,
            isFocused: false
        }

        this.onInputValueChange = this.onInputValueChange.bind(this);
        this.clearProfileName = this.clearProfileName.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);
    }

    componentDidMount() {
        if (!!this.props.Credentials.FirstName) {
            this.inputRef.current!.value = ConstructProfileName(this.props.Credentials.FirstName, this.props.Credentials.LastName);
            this.setState({ hasInputValue: true });
        }
        else if (this.props.BookingName) {
            this.inputRef.current!.value = this.props.BookingName;
            this.setState({ hasInputValue: true });
            this.onInputValueChange();
        }
    }

    /**
     * This function is triggered per change of typing of the input.
     * If:
     *   Valid   -> Write to store, even the value id incomplete;
     *   Invalid -> Clean store immediately, and error message shows.
     */
    onInputValueChange() {

        if (!this.inputRef.current) return;

        this.setState({ hasInputValue: this.inputRef.current.value.length > 0 }); // This should count empty space in.
        const profileName = this.inputRef.current.value.trim(); // This should not count the leading & trailing space in.

        if (this.validateProfileName(profileName)) {
            Dispatch.Auth.SignupProfileName(profileName);
        }
        else {
            Dispatch.Auth.ClearSignupProfileName();
        }
    }

    clearProfileName() {
        this.inputRef.current!.value = "";
        Dispatch.Auth.ClearSignupProfileName();
        this.setState({ message: null, hasInputValue: false });
    }

    /**
     * Validation rules:
     * (1) Can not be empty;
     * (2) Have to have at least one letter;
     * (3) Max length 200 (this constriction is on JSX input level).
     */
    validateProfileName(profileName: string): boolean {
        const length = profileName.length;
        
        if (length === 0) {
            this.setState({ message: "Please enter your name." });
            return false;
        }

        if (!/[a-zA-Z]/.test(profileName)) {
            this.setState({ message: "Your name has to have at least one letter." });
            return false;
        }

        this.setState({ message: null });
        return true;
    }

    onFocusChange(isFocused: boolean) {
        this.setState({ isFocused: isFocused });

        if (!isFocused) this.onInputValueChange();
    }

    render() {

        const inputProp: CustomTextFieldProps  = {
            LabelText: "Your name",
            Name: "Authentication input name",
            AllowAutoFocus: true,
            ErrorMessage: this.state.message || "",
            ErrorMessageStyle: "auth-input-error-message",
            FieldMaxLength: Config.Credentials.ProfileNameMaxLength,
            onClearEvent: this.clearProfileName,
            onBlurEvent: () =>this.onFocusChange(false),
            IsInputInvalid: !!this.state.message,
            IsInputFocussed: this.state.isFocused,
            onFocusEvent: () =>this.onFocusChange(true),
            DoesInputHasValue: this.state.hasInputValue
        }

        return(
            <OutlinedTextField ref={this.inputRef} {...inputProp} />
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore  {
    return {
        Credentials: state.authentication.Credentials,
        BookingName: state.booking.Pickup.Contact.Name
    };
}

export default connect(mapStateToProps)(ResponsiveNameInput);