import { Dispatch } from "../Dispatch";
import { LogEvent } from "../../utils/LogEvent";
import { ApplicationState } from "../../appState";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { LoginStatusKind } from "../Authentication/AuthEntities";
import { PriceGuaranteeToggleProps } from "./PriceGuaranteeToggle";
import { IsDVAUser } from "../BookingTemplate/BookingTemplateHelper";
import { BookingWidgetModeKind } from "../BookingTemplate/BookingTemplateEntities";
import appstore from "../../appStore";
import { OptionalUI } from "../Booking/OptionalParts/OptionalUI";
import { PaymentOptionKind } from "../Payment/PaymentEntities";

/** 
 * Switch ON/OFF the price guarantee toggle.
 * When the price guarantee toggle is switched ON for silver service brand:
 * 1. Fixed fare will be displayed for silver service
 * 2. The selected condition will be set as silver service and no other conditions will be shown
 * 2. Guest users will not be shown any payment option
 */
export function UpdatePriceGuaranteeStatus(props: PriceGuaranteeToggleProps) {

    // turning PG ON
    if (!props.IsPriceGuaranteeSelected) {
        Dispatch.Condition.SetPriceGuarantee();

        // Guest users will not be shown any payment option.
        if (props.LoginStatus === LoginStatusKind.LoggedOut) {
            Dispatch.Booking.ClearPaymentOption();
        }

        LogEvent.OnPriceGuaranteeToggleSwitchedON();
        return;
    }

    // turning PG OFF
    Dispatch.Condition.ClearPriceGuarantee();
    Dispatch.Payment.ClearCardError();

    // CNP payment invalidated
    const state = appstore.getState();

    if (OptionalUI.CnpDisallowedByMetered(state) && state.booking.PaymentOption && (state.booking.PaymentOption.Kind === PaymentOptionKind.CardOrWallet)) {
        Dispatch.Booking.ClearPaymentOption();
    }
}

/** Decides when to show price guarantee toggle based on different flags and properties. */
export function CanShowPriceGuaranteePanel(state: ApplicationState) {

    const selectedAccount = state.booking.AccountData?.SelectedAccount;

    // Feature switch must be ON
    if (!FeatureFlags.PriceGuarantee) return false;

    // Must be a non-DVA user
    if (IsDVAUser(state.authentication.UserProfile)) return false;

    // Must not create/edit template 
    if (state.uiLogicControl.BookingForm.BookingWidgetMode !== BookingWidgetModeKind.Booking) return false;

    // current fleet doesn't support fixed fare. users shouldn't be able to switch to fixed fare.
    if (state.condition.FareEstimate && !state.condition.IsFixedFareAvailable) return false;

    // Not book on account
    if (!state.booking.IsOnAccount) return true;

    // PG bookings on account is disabled.
    if (selectedAccount && !FeatureFlags.PriceGuaranteeWithAccount) return false;

    // Book on account, feature flag enabled, but selected account does not have permission to make Fixed Price bookings.
    if (selectedAccount && !selectedAccount.AllowFixedPriceBookings) return false;    

    return true;
}