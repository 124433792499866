import React, { useEffect } from "react";
import { GuestCreditCard } from "./GuestCreditCard";
import "./CreditCardForm.scss";
import ErrorIcon from '@mui/icons-material/Error';
import { CreditCardFormHeader } from "./CreditCardFormHeader";

/** Inline credit card form in the booking form that allows guest users to make bookings with credit/debit cards without signing in. This is implemented using Braintree SDK's Hosted Fields. Each input field is an iframe inside the provided div element for the input. All the input fields must be put together inside a form. */
export const CreditCardForm: React.FC = () => {

    useEffect(() => {
        GuestCreditCard.StartHostedFields();
    }, []);

    return (
        <div className="credit-card-frame">
            <CreditCardFormHeader />
            <form id="cardForm" >
                <label className="hosted-fields-label" htmlFor="card-number">Credit or Debit card number</label>
                <div id="card-number" className="hosted-field">
                    <span className="end-icon"><ErrorIcon fontSize="small" color="error" /></span>
                </div>
                <div className="card-input-error">Invalid card number</div>

                <div className="hosted-fields-group">
                    <div className="small-input-group">
                        <label className="hosted-fields-label" htmlFor="expiration-date">Expiry date</label>
                        <div id="expiration-date" className="hosted-field">
                            <span className="end-icon"><ErrorIcon fontSize="small" color="error" /></span>
                        </div>
                        <div className="card-input-error">Invalid expiry date</div>
                    </div>

                    <div className="small-input-group">
                        <label className="hosted-fields-label" htmlFor="cvv">CVV</label>
                        <div id="cvv" className="hosted-field">
                            <span className="end-icon"><ErrorIcon fontSize="small" color="error" /></span>
                        </div>
                        <div className="card-input-error">Invalid CVV</div>
                    </div>                    
                </div>                
            </form>
        </div>
    );
}