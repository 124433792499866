import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import { BookingLocationContact, LocationIndex } from "../BookingEntities";
import { Dispatch } from "../../Dispatch";
import { SimpleUserProfile } from "../../User/ProfileEntitiesV2";
import { BookingWidgetModeKind } from '../../BookingTemplate/BookingTemplateEntities';
import { VehicleOption } from "../../Condition/Redux/ConditionEntities";
import { OutlinedTextField, CustomTextFieldProps } from "../Widget/OutlinedTextField";
import { LogEvent } from "../../../utils/LogEvent";
import { ServiceKind } from "../../../utils/service-vehicles/ServiceMetadata";
import { BookingFormKind } from "../../UILogicControl/UILogicControlEntities";
import { PerLocationProps } from "./PerLocationProps";

interface ContactNamePropsFromState {
	PickupDetails: BookingLocationContact;
    DropoffDetails: BookingLocationContact;
	SelectedVehicle: VehicleOption,
	BookingWidgetMode: BookingWidgetModeKind;
	UserProfile : SimpleUserProfile | undefined,
	IsBookingFormStrictValidationModeOn: boolean;
    ActiveBookingForm: BookingFormKind;
}

interface ContactNameState {
	isFocus: boolean;
}

/** Contact Name fields in different sections (pickup/destination/) of booking form. */
class BookingLocationContactNameCore extends React.Component<ContactNamePropsFromState & PerLocationProps, ContactNameState> {

	private inputRef: React.RefObject<HTMLInputElement>;

	constructor(props: ContactNamePropsFromState & PerLocationProps) {
		super(props);
		this.inputRef = React.createRef();

		this.state = {
			isFocus: false,
		};

		this.updateName = this.updateName.bind(this);
		this.inputValueCheckUpdate = this.inputValueCheckUpdate.bind(this);
	}

	updateFocusStatus = (isFocus: boolean) => {
		this.setState({ isFocus: isFocus });
	};

	updateName(e: React.FocusEvent<HTMLInputElement>) {
		this.updateFocusStatus(false);
		const ContactName = e.target.value.trim();

		Dispatch.Booking.ContactName(this.props.Location, ContactName);

		if (ContactName) {
			LogEvent.OnAddingContactName(this.props.Location);
		}
	}

	/**
	 * 4 factors together drive invalid effect
	 * 1) Either ContactName === undefined || ContactName === "" AND active booking form should be passenger;
	 * 2) In strict validation mode;
	 * 3) Input not focused;
	 * 4) Not on booking template mode;
	 */
	IsInputValid = () => {

		if (this.props.BookingWidgetMode !== BookingWidgetModeKind.Booking) return true;

		if (!this.props.IsBookingFormStrictValidationModeOn) return true;

		if (this.state.isFocus) return true;

		if (this.props.PickupDetails.Name) return true; 
        
		// Pickup name is not a mandatory field when parcel booking.
		if (this.props.Location === LocationIndex.Pickup && this.props.ActiveBookingForm === BookingFormKind.ParcelBooking) return true;

		if (this.props.Location === LocationIndex.Dropoff) return true; 

		return false;
				
	};

	inputValueCheckUpdate(contactName: string) {    
		this.inputRef.current!.value = contactName;
	}

	componentDidUpdate(prevProps: Readonly<ContactNamePropsFromState & PerLocationProps>): void {
		if((this.props.Location === LocationIndex.Pickup && this.props.PickupDetails?.Name != prevProps.PickupDetails?.Name) || (this.props.Location === LocationIndex.Dropoff && this.props.DropoffDetails?.Name != prevProps.DropoffDetails?.Name))
		{
			const contactName = this.props.Location === LocationIndex.Pickup ? this.props.PickupDetails.Name ?? "" :  this.props.DropoffDetails.Name ?? "" ;
			this.inputValueCheckUpdate(contactName);
		}
	}
    
	/** Update empty value for contact name in store */
	clearText = () => { 

		Dispatch.Booking.ClearContactName(this.props.Location);
    }

	componentDidMount() {
		const contactName = this.props.Location === LocationIndex.Pickup ? this.props.PickupDetails.Name ?? "" :  this.props.DropoffDetails.Name ?? "" ;
		this.inputValueCheckUpdate(contactName);		
	}

	render() {

		let label : string = "";
		
		if (this.props.Location === LocationIndex.Pickup) {
            /**
             * When booking on accounts, display custom label if present
             * Placeholder is only for non-custom name label fields
             */				
            if (this.props.UserProfile && this.props.UserProfile.CustomerNameLabel) {
                label = this.props.UserProfile.CustomerNameLabel;
            }
            // For parcel booking  
            else if (this.props.SelectedVehicle.Service.kind === ServiceKind.Parcel) {
                label = this.props.ActiveBookingForm === BookingFormKind.ParcelBooking ? "Pickup contact name" : "Parcel contact name";
            }
            else {
                label = "Passenger name";
            }
        }
        else {
            if (this.props.SelectedVehicle.Service.kind === ServiceKind.Parcel) {
                label = "Dropoff contact name";
            }
        }

        // Validate the input
        const isInputInvalid = !this.IsInputValid();
		
		const errorText = `Please enter a ${label.toLowerCase()}`;

		const style = this.props.ActiveBookingForm === BookingFormKind.ParcelBooking ? { marginTop: 0 } : {}

        const inputProp: CustomTextFieldProps  = {
            LabelText: label,
            Name: `ContactName${this.props.Location}`,
            ErrorMessage: errorText,
            onClearEvent: this.clearText,
            onBlurEvent: this.updateName,
            IsInputInvalid: isInputInvalid,
            IsInputFocussed: this.state.isFocus,
            onFocusEvent: this.updateFocusStatus,
            DoesInputHasValue: this.props.Location === LocationIndex.Pickup ? !!this.props.PickupDetails.Name : !!this.props.DropoffDetails.Name
        }

		return (
			<div className="booking-fields-panel" style={style}>
                <OutlinedTextField ref={this.inputRef} {...inputProp} />	
			</div>
		);
	}
}

function mapStateToProps(state: ApplicationState): ContactNamePropsFromState {
	return {
		UserProfile: state.authentication.UserProfile,
		SelectedVehicle: state.condition.SelectedCondition,
		BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
		IsBookingFormStrictValidationModeOn: state.uiLogicControl.BookingForm.IsStrictValidationModeOn,
        PickupDetails: state.booking.Pickup.Contact,
		DropoffDetails: state.booking.Dropoff.Contact,
        ActiveBookingForm: state.uiLogicControl.BookingForm.ActiveBookingForm
	};
}

export const BookingLocationContactName = connect(mapStateToProps)(BookingLocationContactNameCore);
