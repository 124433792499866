import React from "react";
import "./MobileAddFavourites.scss";
import BookingTemplateNameField from '../../BookingTemplate/BookingTemplateNameField';
import BookingAddressGroup from "../Widget/BookingAddressGroup";
import BookingPageLoading from "../Widget/BookingPageLoading";
import BookingButton from "../Widget/BookingButton";
import NotesToDriver from "../PerLocation/NotesToDriver";
import { BookingLocationContactName } from "../PerLocation/BookingLocationContactName";
import { BookingFormKind } from "../../UILogicControl/UILogicControlEntities";
import { FormStepLabel } from "../FormSteps/FormStepLabel";
import { MaybeAccountOption } from "../OptionalParts/MaybeAccountOption";
import { MaybeAccountDetails } from "../OptionalParts/MaybeAccountDetails";
import { MaybeParcelModeSelector } from "../OptionalParts/MaybeParcelModeSelector";
import { MaybeCardSelector } from "../OptionalParts/MaybeCardSelector";
import { MaybeDeliveryOptions } from "../OptionalParts/MaybeDeliveryOptions";
import { BookingFormGroup } from "../FormSteps/BookingFormGroup";
import { VehicleSelector } from "../../Condition/UI/VehicleSelector";
import { BookingPhoneInput } from "../../PhoneNumber/BookingPhoneInput";
import { LocationIndex } from "../BookingEntities";
import { BookingLocationGroup } from "../PerLocation/BookingLocationGroup";
import { useAppState } from "../../../Redux/ReduxHooks";

/**
 * Wrapper component for mobile version to host either the booking form or a list of the user's bookings (selected by tabs).
 */
export const MobileAddFavourites: React.FC = () => {

    const isParcelMode = useAppState(i => i.uiLogicControl.BookingForm.ActiveBookingForm === BookingFormKind.ParcelBooking);

    return (
        <div className="mobile-add-fav-panel">
            <BookingTemplateNameField />
            <MaybeAccountOption />
            <MaybeParcelModeSelector />
            <MaybeAccountDetails />

            {isParcelMode ? <FormInParcelMode /> : <FormInTaxiMode />}
            
            <MaybeDeliveryOptions />
            <BookingButton />
            <BookingPageLoading />
        </div>
    );
}

/** Core booking form inputs in detailed (parcel) mode */
const FormInParcelMode: React.FC = () => (
    <>
        <BookingLocationGroup Location={LocationIndex.Pickup} />
        <BookingLocationGroup Location={LocationIndex.Dropoff} />

        <VehicleSelector />
        <MaybeCardSelector />
    </>
);

/** Core booking form inputs in simple (taxi) mode */
const FormInTaxiMode: React.FC = () => (
    <>
        <BookingFormGroup WithStepLabel={FormStepLabel.BookingDetails}>
            <BookingAddressGroup />
        </BookingFormGroup>

        <VehicleSelector />

        <BookingFormGroup WithStepLabel={FormStepLabel.ContactDetails}>
            <BookingLocationContactName Location={LocationIndex.Pickup} />
            <BookingPhoneInput Location={LocationIndex.Pickup} />
        </BookingFormGroup>

        <MaybeCardSelector />

        <BookingFormGroup WithStepLabel={FormStepLabel.DriverInstruction}>
            <NotesToDriver Location={LocationIndex.Pickup} Combined />
        </BookingFormGroup>
    </>
);