import appstore from "../../appStore";
import { LogEvent } from "../../utils/LogEvent";
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { CreateBookingWithoutSmsVerification } from "../Booking/CreateBookingCommon";
import { DialogKind } from "../Dialog/DialogEntities";
import { Dispatch } from "../Dispatch";
import { ApplePayPlaceholder, CreditCardPlaceholder, GooglePayPlaceholder, PayPalGuestTempPlaceholder } from "../Payment/PaymentEntities";
import { LegacyPhoneShim } from "../PhoneNumber/LegacyPhoneShim";
import { CustomErrorMessages, DescriptiveErrorMessages, WellKnownMessageKind } from "../Utils/ErrorMessages";
import { IsValidLandlineNumber } from "../Utils/PhoneValidation";
import { SmsVerification, StartSmsOutcome } from "./SmsVerification";
import { ContactNumberValidateOutcome, VerificationTrigger } from "./VerificationEntities";

/**
 * Validate the guest users by sending the verification code
 * Maintain the number of failure attempts count
*/
export async function ValidateGuestUser() {

    const attemptCount = appstore.getState().verification.VerificationAttemptCount;
    const currentAttempt = attemptCount + 1;

    const result = await VerifyPhoneForBooking(currentAttempt);

    if (result === ContactNumberValidateOutcome.ErrorFound) {
        Dispatch.Verification.VerificationAttemptCount(currentAttempt);
    }
    // Reset the number of attempts count, after the maximum limit is exceeded
    else if (result === ContactNumberValidateOutcome.ExceededMaxNumberOfAttempts) {
        Dispatch.Verification.VerificationAttemptCount(0);
    }
}

/** 
 * Verify a guest user's phone number just as they are making a booking.
 **/
async function VerifyPhoneForBooking(attemptCount: number): Promise<ContactNumberValidateOutcome> {

    Dispatch.Auth.IsBookingNeededAfterVerification(true);

    // Validate and send verification code
    const result = await VerifyBookingPhone(attemptCount);

    // Show inline error message on the booking widget on validation failure
    if (result === ContactNumberValidateOutcome.ErrorFound || result === ContactNumberValidateOutcome.InvalidPhoneNumber) {
        Dispatch.Verification.ContactNumberErrorMessage(CustomErrorMessages.InvalidPhoneNumber);
    }
    else if (result === ContactNumberValidateOutcome.NotMobileAsRequired) {
        
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.InvalidPhoneMobileOnly);
    }
    else {
        Dispatch.Verification.ClearContactNumberError();
    }

    return result;
}

/**
 * Two categories of phone number are allowed for a booking:
 * 1) Mobile phone, with SMS verification
 * 2) Australian fixed line.
 */
async function VerifyBookingPhone(attemptCount: number): Promise<ContactNumberValidateOutcome> {

    const phoneNumber = appstore.getState().booking.Pickup.Contact.Phone;

    if (phoneNumber == null) {
        return ContactNumberValidateOutcome.InvalidPhoneNumber;
    }

    // Update the UserContactNumberInfo in store
    const legacyPhone = LegacyPhoneShim.ConvertToInfo(phoneNumber);

    Dispatch.Verification.ContactNumber(legacyPhone.Contactnumber!);
    Dispatch.Verification.CountryInfo(legacyPhone.CountryInfo!);

    // Determine if this contact number is mobile
    if (phoneNumber.IsMobile) {
        return await PerformMobileVerification(attemptCount, phoneNumber.FullNumber);
    }

    // This is a potential landline, if valid then make a booking
    if (IsValidLandlineNumber(phoneNumber)) {

        // Guest Payment has stricter conditions: mobile always
        if (MustBeAMobileNumber()) {
            return ContactNumberValidateOutcome.NotMobileAsRequired;
        }

        Dispatch.Verification.ShowLoaderInContactDetails();
        CreateBookingWithoutSmsVerification();
        Dispatch.Verification.HideLoaderInContactDetails();
        return ContactNumberValidateOutcome.ValidLandlineNumber;
    }

    // Invalid number, show inline error
    return ContactNumberValidateOutcome.InvalidPhoneNumber;
}

/** There are some cases where the booking must be made with a valid mobile number. e.g. guest CNP payments. This method checks those scenarios and returns true if a mobile number is required. */
function MustBeAMobileNumber(): boolean {

    const selectedPaymentOption = appstore.getState().booking.PaymentOption;

    if (selectedPaymentOption == PayPalGuestTempPlaceholder) return true;

    // below two placeholders are common for both signed in and guest users. but we don't need to check the logged in status since this method is only called for guest users.
    if (selectedPaymentOption == ApplePayPlaceholder) return true;
    if (selectedPaymentOption == GooglePayPlaceholder) return true;
    if (selectedPaymentOption == CreditCardPlaceholder) return true;

    return false;
}

/**
 * Starts a new SMS Verification challenge for the specified phone number, then shows the Verification dialog.
 * The user journey resumes from the Verification dialog (awkward).
 */
async function PerformMobileVerification(attemptCount: number, phoneNumber: string): Promise<ContactNumberValidateOutcome> {

    Dispatch.Verification.ShowLoaderInContactDetails();
    const result = await SmsVerification.StartChallenge(phoneNumber);
    Dispatch.Verification.HideLoaderInContactDetails();

    if (result.Outcome === StartSmsOutcome.Success) {
        LogEvent.SuccessfulPhoneVerification();

        Dispatch.Verification.WasTriggeredBy(VerificationTrigger.Booking);
        Dispatch.Dialog.ShowDialog(DialogKind.Verification);
        return ContactNumberValidateOutcome.ValidMobileNumber;
    }
    else if (result.Outcome === StartSmsOutcome.InvalidPhoneNumber) {
        LogEvent.InvalidMobileNumberProvided();

        if (attemptCount === 3) {
            LogEvent.OnProvidingInvalidMobileNumberFourthTime();
            Dispatch.Dialog.SetDescriptiveErrorMessage({ ...DescriptiveErrorMessages.InvalidPhone });
            Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
            return ContactNumberValidateOutcome.ExceededMaxNumberOfAttempts;
        }

        return ContactNumberValidateOutcome.ErrorFound;
    }
    else {
        // Display the generic error message for timeouts and other unanticipated errors asking the user to call 132227
        LogEvent.SendingVerificationCodeFailure(result.ErrorMessage);
        //ShowDialogSimpleErrorMessage(WellKnownMessageKind.GeneralFailure);
        Dispatch.Dialog.ShowDialog(DialogKind.TechnicalDifficulties);
        return ContactNumberValidateOutcome.VerificationFailed;
    }
}