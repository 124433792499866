import React, { useEffect } from "react";
import { useAppState } from "../../Redux/ReduxHooks";
import { OptionalUI } from "../Booking/OptionalParts/OptionalUI";
import { Dispatch } from "../Dispatch";
import braintree from "braintree-web";
import { SdkLoadingState } from "../PayPal/PayPalState";
import { GooglePay } from "./GooglePay";
import { GetValues } from "../../Config/MyAppConfig";
import { AddGooglePayFunnel } from "./AddGooglePayFunnel";
import { GetOrMakeBraintreeClient } from "../Payment/GetOrMakeBraintreeClient";

/**
 * Manages the deferred loading of the GooglePay SDK. 
 * Instead of loading at startup, we wait for a signal from the UI (e.g. pickup address selected).
 * This avoids slowing down the startup experience.
 * This component doesn't render anything; it just receives and sends events.
 */
export const GooglePayLoadingWatcher: React.FC = () => {

    const isFeatureEnabled = useAppState(OptionalUI.AddGooglePay);
    const loadingState = useAppState(i => i.GooglePay.LoadingStatus);
    const hasPickup = useAppState(i => !!i.booking.Pickup.Address);

    // pre-existing states that should trigger a load
    useEffect(() => {

        // Google Pay is not supported
        if (!isFeatureEnabled) return;

        if (hasPickup) {
            Dispatch.GooglePay.ReadyToLoad();
        }

    }, [hasPickup]);

    // once only load
    useEffect(() => {

        // Google Pay is not supported
        if (!isFeatureEnabled) return;

        // UI flow has advanced far enough
        if (loadingState === SdkLoadingState.RequestedNotStarted) {
            StartLoadingGooglePaySdk();
        }
    }, [isFeatureEnabled, loadingState]);

    return null;
}

/**
 * Intended to run only once.
 * This method just manages the redux state changes.
 */
async function StartLoadingGooglePaySdk() {

    Dispatch.GooglePay.LoadStarting();

    const isSuccess = await TryLoadUpToGooglePaySdk();

    if (isSuccess) {
        Dispatch.GooglePay.LoadSucceeded();
    }
    else {
        Dispatch.GooglePay.LoadFailed();
    }
}

/**
 * Loads the GooglePay SDK. There are three parts, each of which could fail:
 * 1) Braintree SDK
 * 2) Google Pay SDK.
 * 3) Check the device/browser support for Google Pay
 */
async function TryLoadUpToGooglePaySdk(): Promise<boolean> {

    const paymentsClient = new google.payments.api.PaymentsClient({
        environment: GetValues().GooglePayEnvironment
    });

    // 1) BrainTree SDK
    const braintreeClient = await GetOrMakeBraintreeClient();

    if (!braintreeClient) {
        return false;
    }

    // 2) Google Pay SDK
    let googlePayClient: braintree.GooglePayment;

    try {
        googlePayClient = await braintree.googlePayment.create({
            client: braintreeClient,
            googlePayVersion: 2,
            googleMerchantId: GetValues().GoogleMerchantId
        });
    }
    catch (error) {
        AddGooglePayFunnel.Exception("GooglePay Client", error, false);
        return false;
    }

    // 3) Check if the device/browser supports GooglePay
    try {
        const isGooglePayReady = await paymentsClient.isReadyToPay({
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: (await googlePayClient.createPaymentDataRequest()).allowedPaymentMethods,
            existingPaymentMethodRequired: true
        });

        // only set this when the result is true, the default value is false.
        if (isGooglePayReady.result) Dispatch.GooglePay.GooglePaySupported();
    }
    catch (error) {
        AddGooglePayFunnel.Exception("IsReadyToPay", error, false);
        return false;
    }    

    // OK!
    GooglePay.SdkIsReady(googlePayClient, paymentsClient);
    return true;
}