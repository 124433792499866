import { AuthToken } from "../modules/Authentication/AuthEntities";
import { Auth0Error } from "auth0-js";
import { FullStoryAPI } from 'react-fullstory';
import { BookingTemplate } from "../modules/BookingTemplate/BookingTemplateEntities";
import { CredentialLoggingInput } from "../modules/Authentication/Login/LoginEntities";
import { VehicleOption } from "../modules/Condition/Redux/ConditionEntities";
import { FeatureId } from "../modules/WhatsNew/FeaturesEntities";
import { SimpleUserProfile } from "../modules/User/ProfileEntitiesV2";
import appstore from "../appStore";
import { DeriveUserIdFromAuthToken } from "../modules/Authentication/ProcessUserProfile";
import { QuickBookSource } from "../modules/Booking/QuickBook/QuickBookEntities";
import { RecentTripButtonKind } from "../modules/UILogicControl/UILogicControlEntities";
import { LocationIndex } from "../modules/Booking//BookingEntities";
import { LogBookingCreationData } from "../modules/Booking/LogBookingCreation";

/** 
 *  Utility code to log custom events in App Insights.
 *  The aim is to move all App Insights events into this file. This will make it easy to cross reference App Insights logs with the code that created them.
 *  It also ensures that an event that is logged from multiple places will have a consistent set of custom properties.
 */
export const LogEvent = {

    /**
     * A user has logged in successfully.
     * Event name: "User Sign In Success".
     */
    UserSignInSuccess: function(authToken: AuthToken) {

        const accountType = /@/i.test(authToken.email) ? "Email" : "Pure username";

        LogEventInAppInsight("User Sign In Success", { Type: accountType });
    },

    /**
     * A user has failed the Auth0 portion of a login attempt.
     * Event name: "User Sign In Fail".
     */
    UserSignInFailed: function (error: Auth0Error) {

        // record everything for maximum troubleshooting diagnostics
        const errorMessage = JSON.stringify(error);

        LogEventInAppInsight("User Sign In Fail", {
            ErrorMessage: errorMessage
        });
    },

    /**
     * A user has failed the Auth0 portion of a signup attempt.
     * Event name: "User Sign Up Fail".
     */
    UserSignUpFailed: function (error: Auth0Error) {

        // record everything for maximum troubleshooting diagnostics
        const errorMessage = JSON.stringify(error);

        LogEventInAppInsight("User Sign Up Fail", {
            ErrorMessage: errorMessage
        });
    },

    /**
     * A booking template has been saved successfully.
     * Event name: "Booking template saved".
     */
    BookingTemplateSaved: function (template: BookingTemplate) {

        LogEventInAppInsight("Booking template saved", {
            ID: template.BookingTemplateId.toString(),
            Name: template.Name
        });
    },

    /**
     * A booking template has been removed successfully.
     * Event name: "Booking template removed".
     */
    BookingTemplateRemoved: function () {

        LogEventInAppInsight("Booking template removed");
    },

    /**
     * A booking template has been selected to populate booking form.
     * Event name: "Booking template selected".
     */
    BookingTemplateSelected: function (template: BookingTemplate) {

        LogEventInAppInsight("Booking template selected", {
            ID: template.BookingTemplateId.toString(),
            Name: template.Name
        });
    },

    /**
     * "Add a new card" option is selected from the payment method
     * Event name: "Add a new card from card selector"
     */
    AddNewCardSelected: function() {
        LogEventInAppInsight("Add a new card from card selector");
    },

    /**
     * Display the add card screen after the newly registered user signed up
     * Event name: "Display add card screen after Sign up"
     */
    ShowAddCardScreenAfterSignup: function() {
        LogEventInAppInsight("Display add card screen after Sign up");
    },

    /**
     * Guest user has clicked on the "Add a new card" option and signed up
     * Event name: "Signup via selecting the Add a new card option"
     */
    SignupTriggeredByAddNewCard: function() {
        LogEventInAppInsight("Signup via selecting the Add a new card option");
    },

    /**
     * Guest user has clicked on the "Sign up" button present on the header
     * Event name: "Register via Sign up button present on the header"
     */
    RegisterBySignupButton: function() {
        LogEventInAppInsight("Register via Sign up button present on the header");
    },

    /**
     * On Signup link selection of favourites feature card.
     * Event name: "Signup via selecting the Signup link on favourites feature card"     
     */
    SignupTriggeredByFavouritesCard: function() {        
        LogEventInAppInsight("Signup via selecting the Signup link on favourites feature card");
    },

    /**
     * Payment method is changed from the dropdown
     * Event name: "Change the payment method"
     */
    PaymentMethodChanged: function() {
        LogEventInAppInsight("Change the payment method");
    },

    /**
     * Reset password successfully.
     * Event name: "Reset password success".
     */
    ResetPasswordSuccess: function (input: CredentialLoggingInput) {
        LogEventInAppInsight("Reset password success", {
            Username: input.Username
        });
    },

    /**
     * Reset password failed.
     * Event name: "Reset password failed".
     */
    ResetPasswordFailed: function (input: CredentialLoggingInput) {

        LogEventInAppInsight("Reset password failed", {
            Username: input.Username,
            ErrorMessage: input.ErrorMessage!
        });
    },

    /**
     * A feature card has been acknowledged successfully.
     * Event name: "Feature acknowledged".
     */
    FeatureAcknowledged: function (featureId: FeatureId) {

        // Extract feature name using id
        const featureName = FeatureId[featureId];

        LogEventInAppInsight("Feature acknowledged", {
            FeatureName: featureName
        });
        
        // Sending the acknowledged feature data to Fullstory
        FullStoryAPI('event', "Feature acknowledged", featureName);
    },

    /**
     * Booking tab is selected e.g. QuickBook or NewBooking
     * Event name: "Booking tab selected"
     */
    BookingTabSelected: function (bookingTabName: string) {

        LogEventInAppInsight("Booking tab selected", {
            BookingTabName: bookingTabName
        });        
    },

    /**
     * On QuickBook tab, create favourite button is clicked.
     * Event name: "Create favourite button clicked"
     */
    CreateFavouriteButtonClicked: function() {
        LogEventInAppInsight("Create favourite button clicked");
    },    
    
    /**
     * On Vehicle selection.
     * Event name: "Vehicle selected"
     */
    OnVehicleSelection: function (vehicle: VehicleOption) {
        
        LogEventInAppInsight("Vehicle selected", {
            ServiceName: vehicle.Service.displayName,
            Seats: vehicle.Service.short
        } );
    },

    /**
     * On Parcel delivery link clicked.
     * Event name: "Parcel delivery link clicked on Parcel feature card"
     */
    OnParcelDeliveryLinkClicked: function() {        
        LogEventInAppInsight("Parcel delivery link clicked on Parcel feature card");
    },

    /** On Favourite link clicked, to switch to QuickBook tab.
     * Event name: "Favourite link clicked on Favourite feature card"
     */
    OnFavouriteLinkClicked: function() {
        LogEventInAppInsight("Favourite link clicked on Favourite feature card");
    },

    /**
     * A "now" booking is triggered.
     * Event name: "Book for now"
     */
    BookForNowTriggered: function() {
        LogEventInAppInsight("Book for now");
    },

    /**
     * A "future" booking is triggered.
     * Event name: "Book for later"
     */
    BookForFutureTriggered: function() {
        LogEventInAppInsight("Book for later");
    },

    /**
     * Invalid booking inputs.
     * Event name: "We will need a name and pickup address to make this booking"
     */
    InvalidBookingInputs: function() {
        LogEventInAppInsight("We will need a name and pickup address to make this booking");
    },

    /**
     * A booking triggered.
     * Event name: "Clicked 'Book' button"
     */
    BookingTriggered: function() {
        LogEventInAppInsight("Clicked 'Book' button");
    },

    /**
     * Invalid inputs for creating a template.
     * Event name:"We will need a name to save this favourite template."
     */
    InvalidCreatingTemplateInputs: function() {
        LogEventInAppInsight("We will need a name to save this favourite template.");
    },

    /**
     * Get user profile successfully for logged-in users.
     * Event name: "Get User Profile Succeed".
     */
    GetUserProfileSuccess: function(profile: SimpleUserProfile) {

        const userType = !profile.CompanyId ? "Individual" : "Company";    

        LogEventInAppInsight("Get User Profile Succeed", { UserID: profile.UserId, UserType: userType, ContactName: profile.ContactName });
    },

    /** 
     * An attempt to load the user's profile failed.
     * This usually means there was a problem logging in, e.g. the Auth token was not valid.
     */
    GetUserProfileFailure: function() {

        LogEventInAppInsight("Get User Profile Fail", {
            UserId: GetUserInfoBestEffort(),
        });
    },

    /**
     * Successfully update the user's verification status
     * Event name: "User IsVerified Update Success".
     */
    UpdateUserVerificationStatusSuccess: function(userId: string) {

        LogEventInAppInsight("User IsVerified Update Success", { UserId: userId });
    },

    /**
     * Failed to update the user's verification status
     * Event name: "User IsVerified Update Failure".
     */
    UpdateUserVerificationStatusFail: function(userId: string) {

        LogEventInAppInsight("User IsVerified Update Failure", { UserId: userId });
    },

    /** Signup by clicking on the button or link on the Quick Book tab. */
    SignupFromQuickBook: function () {
        LogEventInAppInsight("Signup from Quick Book");
    },

    /** Login by clicking on the button or link on the Quick Book tab (available only in the Cabcharge app). */
    LoginFromQuickBook: function () {
        LogEventInAppInsight("Login from Quick Book");
    },

    /**
     * An Auth0 session was successfully renewed, meaning the user stays logged in.
     * Logs the associated user ID:
     * 13cabs: Booking API User ID
     * Cabcharge: Auth0 subject, e.g. "auth0|abc123def456"
     */
    Auth0RenewSuccess: function(newToken: AuthToken) {

        LogEventInAppInsight("Auth0 Session Renew Success", {
            UserId: DeriveUserIdFromAuthToken(newToken),
            User: GetUserInfoBestEffort(), // just in case it's different
        });
    },

    /**
     * An attempt to renew an Auth0 session failed.
     */
    Auth0RenewFailure: function(error: Auth0Error) {

        LogEventInAppInsight("Auth0 Session Renew Fail", {
            User: GetUserInfoBestEffort(),
            ErrorMessage: JSON.stringify(error),
        });
    },

    /**
     * When restoring an Auth0 login session, the user's identity has apparently changed. We will trust what is in Auth0 and not use the persisted Profile data.
     */
    LoginRestoreUserChanged: function(oldUserId: string, newUserId: string) {

        LogEventInAppInsight("Login Restore User Changed", {
            oldUserId,
            newUserId,
        });
    },

    /**
     * When restoring a persisted login, the user identity in the Auth token (from Auth0) didn't match the value attached to the Profile (from local storage).
     * This will trigger a refresh of the profile for safety.
     */
    LoginRestoreProfileMismatch: function(profile: SimpleUserProfile, token: AuthToken) {
        LogEventInAppInsight("Login Restore PRofile Mismatch", {
            TokenIdentity: token.sub!,
            ProfileIdentity: profile.TokenUserId,
        });
    },

    /**
     * Tracking Http referrer when page refresh
     */
    HttpReferrer: function(referrer: string | undefined) {
        const s = !!referrer ? referrer : "";
        LogEventInAppInsight("Http Referrer", { Referrer: s });
    },

    /**
     * The user clicked the button to get gps location
     */
    TryGetGpsLocation: function() {
        LogEventInAppInsight("Try to get gps location");
    },

    /**
     * Successfully got gps location
     */
    GetGpsLocationSuccess: function() {
        LogEventInAppInsight("Get gps location success");
    },

    /**
     * Failed to get gps location
     */
    GetGpsLocationFailure: function(error: string) {
        LogEventInAppInsight("Get gps location fail", { Error: error });
    },

    /**
     * Failed to get gps location
     */
    GetGpsLocationNotSupported: function(device: string) {
        LogEventInAppInsight("Get gps location fail", { Device: device });
    },

    /**
     * Timeout happened when getting gps location
     */
    GetGpsLocationTimeout: function() {
        LogEventInAppInsight("Get gps location timeout");
    },

    /**
     * Google geocoder failed
     */
    GoogleGeoCoderFailure: function() {
        LogEventInAppInsight("Google geocoder failure");
    },

    /**
     * Clicked Track this booking
     */
    TrackBookingClicked: function() {
        LogEventInAppInsight("Clicked Track this booking");
    },

    /** Successfully shared with the WebAPI share method (native share in mobile) */
    ShareWithWebAPISuccess: function() {
        LogEventInAppInsight("Shared with WebAPI successfully");
    },

    /** Share with WebAPI share method failed. Error message from the API is also logged. */
    ShareWithWebAPIFailed: function(e: string) {
        LogEventInAppInsight("Share with WebAPI failed", { error: e });
    },

    /** WebAPI share method is supported in the current device or the browser. */
    ShareIsNotSupported: function() {
        LogEventInAppInsight("Share API is not supported in this device or browser");
    },

    /** Successfully sent verification code to a mobile number */
    SendVerificationCodeToMobileSuccess: function() {
        LogEventInAppInsight("Phone verification success");
    },

    /** Failed to send verification code to a mobile number */
    SendVerificationCodeToMobileFail: function(error: string) {
        LogEventInAppInsight("Send verification failed", { message: error });
    },

    /** User clicked on 'Book again' or 'Book return' button in one of the various booking lists. */
    SelectedBookingToRebook: function (source: QuickBookSource, tripType: RecentTripButtonKind) {
        LogEventInAppInsight(`Booking from ${source}`, {
            TripType: tripType
        });
    },

    /** Check pickup address service availability success, which works for both BookingController and BookingControllerV2 */
    CheckPickupAddressAvailabilitySuccess: function() {
        LogEventInAppInsight("Pickup address success");
    },

    /** Check pickup address service availability failure, which works for both BookingController and BookingControllerV2 */
    CheckPickupAddressAvailabilityFailure: function (errorMessage: string, place: google.maps.places.PlaceResult) {
        LogEventInAppInsight("Pickup address failure", {
            message: errorMessage, 
            source: "Booking",
            placeId: place.place_id!,
            lat: place.geometry!.location!.lat().toString(), 
            long: place.geometry!.location!.lng().toString(), 
            pickup: place.formatted_address!,
        });
    },

    /** Logging even when a booking has been made successfully */
    BookingCreationSuccess: function (bookingData: LogBookingCreationData) {

        LogEventInAppInsight("Booking Creation Success", { ...bookingData });
    },

    /** Booking was made with international number */
    BookingCreatedWithInternationalNumber: function (countryName: string) {

        LogEventInAppInsight("Booking made with international number", { country: countryName });
    },

    /**
     * TimedOut error while booking creation
     */
    BookingCreationTimedOut: function() {
        LogEventInAppInsight("Booking creation timeout");
    },

    /**
     * Failure in booking creation
     */
    BookingCreationFailed: function(errorMessage?: string) {

        const errMessage = errorMessage ?? "";
        
        LogEventInAppInsight("Booking creation failure", {
            message: errMessage
        });
    },

    /**
     * Dropoff address selected from Google autocomplete dropdown
     */
    DropoffAddressSelected: function() {
        LogEventInAppInsight("Dropoff selected");
    },

    /**
     * Clicked "Yes" button on the cancel booking dialog
     */
    PerformedBookingCancellation: function() {
        LogEventInAppInsight("Clicked Yes Cancel");
    },

    /**
     * Clicked "OK" button on the booking confirmaton dialog
     */
    OkButtonClickedOnBookingConfirmation: function() {
        LogEventInAppInsight("Clicked 'OK' in booking success popup");
    },

     /**
     * Booking confirmaton dialog loaded after successful booking creation
     */
    OnBookingConfirmationLoad: function() {
        LogEventInAppInsight("Booking confirmation");
    },

    /**
     * Provided notes to driver
     */
    OnAddingDriverNotes: function() {
        LogEventInAppInsight("Notes to driver");
    },

    /**
     * Provided passenger name
     */
    OnAddingPassengerName: function() {
        LogEventInAppInsight("Populate name");
    },

    /**
     * Landed on verification page
     */
    OnVerificationPageLoad: function() {
        LogEventInAppInsight("Verification page");
    },

    /**
     * Verification code resend
     */
    OnResendingVerificationCode: function() {
        LogEventInAppInsight("Resend verification code");
    },

    /**
     * Reached max attempt limit for sending verification code
     */
    OnResendingVerificationCodeFourthTime: function() {
        LogEventInAppInsight("Resend code for the fourth time");
    },

    /**
     * Valid verification code entered
     */
    ValidVerificationCodeEntered: function() {
        LogEventInAppInsight("Verification code input success");
    },
   
    /**
     * Invalid verification code entered
     */
    InvalidVerificationCodeEntered: function() {
        LogEventInAppInsight("Invalid verification code");
    },

    /**
     * Phone number is successfully verified
     */
    SuccessfulPhoneVerification: function() {
        LogEventInAppInsight("Phone verification success");
    },

    /**
     * Invalid mobile number provided
     */
    InvalidMobileNumberProvided: function() {
        LogEventInAppInsight("Incorrect mobile number");
    },

    /**
     * Reached max attempt limit for providing valid mobile number
     */
    OnProvidingInvalidMobileNumberFourthTime: function() {
        LogEventInAppInsight("Incorrect mobile number for the fourth time");
    },

    /**
     * Failed to send the verification code to the provided mobile number
     */
    SendingVerificationCodeFailure: function(errorMessage: string) {
        LogEventInAppInsight("Send verification failed", {
            message: errorMessage
        });
    },

    /**
     * Landed on contact details page
     */
    OnDisplayingContactDetailsPage: function() {
        LogEventInAppInsight("Contact details page");
    },

    /**
     * Successfully added payment card
     */
    AddedNewPaymentCard: function() {
        LogEventInAppInsight("Added a new card successfully");
    },

    /**
     * Clicked on remove card button on payment wallet list
     */
    OnRemovingPaymentCard: function() {
        LogEventInAppInsight("Remove a payment card");
    },

    /**
     * Modifying the default payment card
     */
    OnChangingDefaultPaymentCard: function() {
        LogEventInAppInsight("Change default payment card");
    },

    /**
     * Clicked on add card button on payment wallet list
     */
    OnAddingNewPaymentCard: function() {
        LogEventInAppInsight("Add a new card from My wallet");
    },

    /**
     * Clicked on "Yes" button on remove payment card confirmation dialog
     */
    OnAgreeingToRemovePaymentCard: function() {
        LogEventInAppInsight("Clicked Yes Remove Card");
    },

    /**
     * Landed on booking page
     */
    OnBookingPageLoad: function() {
        LogEventInAppInsight("Booking page");
    },

   /**
     * Pickup address selected from Google autocomplete dropdown
     */
    OnPickupAddressSelection: function() {
        LogEventInAppInsight("Pickup address selected");
    },

    /**
     * User has clicked the download mobile app button
     */
    OnDownloadMobileAppButtonClicked: function() {
        LogEventInAppInsight("Download mobile app button clicked");
    },

    /**
     * User has switched ON the price guarantee toggle
     */
    OnPriceGuaranteeToggleSwitchedON: function() {
        LogEventInAppInsight("Price guarantee toggle switched ON");
    },

    /**
     * When the user encountered a location in a different time zone.
     */
    TimeZoneMismatch: function (myZoneId: string, observedZoneId: string, place: google.maps.places.PlaceResult) {
        LogEventInAppInsight("Time Zone Mismatch", {
            MyZoneId: myZoneId,
            ObservedZoneId: observedZoneId,
            Latitude: place.geometry!.location!.lat().toString(),
            Longitude: place.geometry!.location!.lng().toString(),
            Address: place.formatted_address!,
        });
    },

    OnAddingContactName: function(kind: LocationIndex) {
        LogEventInAppInsight(`Populate ${kind.toString()} name`);
    },

    /** User clicked the Add PayPal button. */
    AddPayPalStart: function () {

        const fullDetails = {
            UserId: GetUserInfoBestEffort(),
        };

        LogEventInAppInsight("AddPayPalStart", fullDetails);

    },

    /** The user has left the PayPal funnel early. Note where. */
    PayPalFunnelExit: function(funnelStage: string, errorKind: string, errorDetails: Record<string, string>) {

        const fullDetails = {
            ...errorDetails,
            UserId: GetUserInfoBestEffort(),
            Stage: funnelStage,
            ErrorKind: errorKind,
        };

        LogEventInAppInsight("PayPalFunnelExit", fullDetails);
    },

    /** User selected Apple Pay and clicked Request Booking button. */
    AddApplePayStart: function () {
        const fullDetails = {
            UserId: GetUserInfoBestEffort(),
        };

        LogEventInAppInsight("AddApplePayStart", fullDetails);
    },

    /** The user has left the Apple Pay funnel early. */
    ApplePayFunnelExit: function (funnelStage: string, errorKind: string, errorDetails: Record<string, string>) {

        const fullDetails = {
            ...errorDetails,
            UserId: GetUserInfoBestEffort(),
            Stage: funnelStage,
            ErrorKind: errorKind,
        };

        LogEventInAppInsight("ApplePayFunnelExit", fullDetails);
    },

    /** User selected Google Pay and clicked Request Booking button. */
    AddGooglePayStart: function () {
        const fullDetails = {
            UserId: GetUserInfoBestEffort(),
        };

        LogEventInAppInsight("AddGooglePayStart", fullDetails);
    },

    /** The user has left the Google Pay funnel early. */
    GooglePayFunnelExit: function (funnelStage: string, errorKind: string, errorDetails: Record<string, string>) {

        const fullDetails = {
            ...errorDetails,
            UserId: GetUserInfoBestEffort(),
            Stage: funnelStage,
            ErrorKind: errorKind,
        };

        LogEventInAppInsight("GooglePayFunnelExit", fullDetails);
    },

    /** The user has left the Add payment method (PayPal, ApplePay etc) funnel early. This happens during the creation of Braintree client, before creating different payment clients. */
    AddPaymentMethodFunnelExit: function (funnelStage: string, errorKind: string, errorDetails: Record<string, string>) {

        const fullDetails = {
            ...errorDetails,
            UserId: GetUserInfoBestEffort(),
            Stage: funnelStage,
            ErrorKind: errorKind,
        };

        LogEventInAppInsight("AddPaymentMethodFunnelExit", fullDetails);
    },

    /** User clicks on 'Request booking' button and the selected payment card requires 3DS check. */
    ThreeDSecureStart: function () {
        const fullDetails = {
            UserId: GetUserInfoBestEffort(),
        };

        LogEventInAppInsight("ThreeDSecureStart", fullDetails);
    },

    /** The user has left the 3DS funnel early. */
    ThreeDSecureFunnelExit: function (funnelStage: string, errorKind: string, errorDetails: Record<string, string>) {

        const fullDetails = {
            ...errorDetails,
            UserId: GetUserInfoBestEffort(),
            Stage: funnelStage,
            ErrorKind: errorKind,
        };

        LogEventInAppInsight("ThreeDSecureFunnelExit", fullDetails);
    },

    /** The user has left the guest credit card funnel early. */
    GuestCreditCardFunnelExit: function (funnelStage: string, errorKind: string, errorDetails: Record<string, string>) {

        const fullDetails = {
            ...errorDetails,
            UserId: GetUserInfoBestEffort(),
            Stage: funnelStage,
            ErrorKind: errorKind,
        };

        LogEventInAppInsight("GuestCreditCardFunnelExit", fullDetails);
    },
};

/** 
 *  Try to describe the currently logged in user.
 *  This is used in error situations where the data may be missing or broken, so we will be quite defensive.
 */
function GetUserInfoBestEffort(): string {
    
    const authState = appstore.getState().authentication;

    // try 1: profile
    const profile = authState.UserProfile;
    if (profile) return JSON.stringify(profile);

    // try 2: auth token
    const token = authState.AuthToken;
    if (token) return DeriveUserIdFromAuthToken(token);

    // give up (probably a guest user)
    return "";
}

/**
 * Appends the below values to the incoming data
 * 1. LayoutMode i.e. Mobile or Desktop
 * 2. Login Status i.e. Logged In, Logged Out or Get User Profile In Progress
 */
function LogEventInAppInsight(eventName: string, eventValues?: Record<string, string>): void {
    
    const { authentication, uiLogicControl } = appstore.getState();
    
    var updatedValues = { ...eventValues, Layout: uiLogicControl.LayoutMode, LoginStatus: authentication.LoginStatus  };
    appInsights.trackEvent(eventName, updatedValues);
}
