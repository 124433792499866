import { UILogicControlState, RecentTripLoadKind, BookingTabKind, CredentialPopupTrigger, QuickBookExpandedSection, UILayoutMode, BookingFormKind, EditorState } from "./UILogicControlEntities";
import { DataLoadingStatus } from "../Condition/Redux/ConditionEntities";
import { BookingWidgetModeKind, BookingTemplateLoadKind } from '../BookingTemplate/BookingTemplateEntities';

/** A reasonable default for a single UI editor. */
const defaultEditState: EditorState = {
    IsEditInProgress: false,
    IsEmpty: true,
    IsValid: true,
    ErrorMessage: null,
};

/** Initial value of UILogicControl */
export const InitUILogicControlState: UILogicControlState = {
    BookingForm: {
        ApiRunningStatus: DataLoadingStatus.Idle,
        IsStrictValidationModeOn: false,
        IsLandingPageFinished: false,
        RecentTripLoadingStatus: RecentTripLoadKind.Idle,
        SelectedBookingTab: BookingTabKind.NewBooking,
        IsSelectedVehicleServiceable: true,
        BookingWidgetMode: BookingWidgetModeKind.Booking,
        BookingTemplateLoadingStatus: BookingTemplateLoadKind.Idle,
        QuickBookExpandedStatus: QuickBookExpandedSection.RecentBookings,
        SpecifiedAccountNotFound: false,
        ShouldShowHeader: true,        
        IsInvalidConditionForSatss: false,
        ShouldShowFareEstimateMesssage: false,
        ShouldShowSnackbarMesssage: false,
        ActiveBookingForm: BookingFormKind.PassengerBooking,
        Pickup: {
            ContactNumber: defaultEditState,
        },
        Dropoff: {
            ContactNumber: defaultEditState,
        },
    },
    AddressStatus: {
        Pickup: {
            IsFocus: false,
            DoesInputHaveValue: false,
            IsValid:true
        },
        Dropoff: {
            IsFocus: false,
            DoesInputHaveValue: false,
            IsValid: true
        }
    },
    Credentials: {
        PopupTriggerSource: CredentialPopupTrigger.FromMenu,
        ApiRunningStatus: DataLoadingStatus.Idle
    },
    LayoutMode: UILayoutMode.Desktop,
    ShowMenu: false,
    ShouldShowLoading: false,
    CardSelectorRemountCounter: 0,
};