import braintree from "braintree-web";
import { GetOrMakeBraintreeClient } from "../Payment/GetOrMakeBraintreeClient";
import { Dispatch } from "../Dispatch";
import { ThreeDSecure } from "../ThreeDSecure/ThreeDSecure";
import { GuestCreditCardFunnel } from "./GuestCreditCardFunnel";

/** Adding a credit/debit card as the payment method for a booking as a guest. */
export namespace GuestCreditCard {

    /** Hosted Fields clients from Braintree SDK. */
    let hostedFieldsInstance: braintree.HostedFields | null = null;

    /** Braintree client SDK for the hosted fields. */
    let braintreeClient: braintree.Client | null = null;

    /** Start Hosted Fields SDK (following Braintree SDK initialisation). */
    export async function StartHostedFields() {
        const clientInstance = await GetOrMakeBraintreeClient();

        if (!clientInstance) {
            return;
        }

        braintreeClient = clientInstance;
        await createHostedFields();
    }

    /** Create Credit card input fields (Hosted Fields) using Braintree SDK. */
    async function createHostedFields() {

        try {
            hostedFieldsInstance = await braintree.hostedFields.create({
                client: braintreeClient!,
                styles: {
                    'input': {
                        'font-size': '1rem',
                        'font-family': "Visby CF, Arial",
                        'letter-spacing': '0.15px',
                        'color': 'rgba(0, 0, 0, 0.87)',
                        'width': '90%',
                    },
                    ':focus': {
                        'color': 'black'
                    },
                    '.valid': {
                        'color': 'black'
                    },
                    '.invalid': {
                        'color': '#b00020'
                    }
                },
                fields: {
                    number: {
                        selector: '#card-number',
                        placeholder: '4555 5555 5555 5555'
                    },
                    cvv: {
                        selector: '#cvv',
                        placeholder: '123'
                    },
                    expirationDate: {
                        selector: '#expiration-date',
                        placeholder: 'MM/YY'
                    }
                }
            });
        }
        catch (error) {
            GuestCreditCardFunnel.Exception("Create Hosted Fields", error, false);
            return;
        }
    }

    /** Register credit/debit card in Braintree and obtain the tokenised nonce. */
    export async function RegisterCreditCardAndProceedBooking() {

        Dispatch.UILogicControl.ShowLoading();

        let tokenisedNonce: braintree.HostedFieldsTokenizePayload;

        try {
            tokenisedNonce = await hostedFieldsInstance!.tokenize({
                vault: true
            });
        }
        catch (error) {
            GuestCreditCardFunnel.Exception("Tokenise Hosted Fields", error, true);
            return;
        }

        await ThreeDSecure.Run3DsVerificationWithoutLoadingNonceAndProceedBooking(tokenisedNonce.nonce, tokenisedNonce.details.bin);

        Dispatch.UILogicControl.HideLoading();
    }
}