import { LoginStatusKind } from "../Authentication/AuthEntities";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { PaymentOptionKind } from "../Payment/PaymentEntities";
import { IsDVAUser } from "../BookingTemplate/BookingTemplateHelper";
import { DateTime } from "luxon";
import { ApplicationState } from "../../appState";
import { VehicleFareDetail } from "../../Services/FixedFareEntities";
import { PreviewFeatureId } from "../Features/FeatureEntities";
import appstore from "../../appStore";

/**
 * The basic idea is to ignore small changes in times and consider them the same, for the purposes of getting a new fare, at least.
 */
export function RoundTime(rawTime: DateTime): DateTime {
    return rawTime.set({
        second: 0,
        minute: 0,
    });
};

/** 
 *  Decide if the user can book with Fixed fare based on different criteria. 
 *  WARNING: the FixedFareWithCash feature flag is always true, so this method will always return true.
 *  This seems weird given how much apparent business logic it has. Should we just get rid of it?
 */
export function CanUserMakeFixedFareBookings(currentState: ApplicationState) {
    const selectedAccount = currentState.booking.AccountData?.SelectedAccount;

    // Fixed fare booking with cash is allowed. Hence no need further checking.
    if (FeatureFlags.FixedFareWithCash) return true;

    // Guest user
    if (currentState.authentication.LoginStatus === LoginStatusKind.LoggedOut) return false;

    // DVA user
    if (IsDVAUser(currentState.authentication.UserProfile)) return false;

    // Book with account. But the selected account has no permission to book with fixed price.
    if (selectedAccount && !selectedAccount.AllowFixedPriceBookings) return false;

    // Book with account, PG bookings on account is disabled.
    if (selectedAccount && !FeatureFlags.PriceGuaranteeWithAccount) return false;

    // Book on account
    if (selectedAccount) return true;

    // Signed in user, but no cards added in the profile
    if (currentState.payment.AllCards.length === 0) return false;

    // Payment card option is not selected
    if (currentState.booking.PaymentOption?.Kind !== PaymentOptionKind.CardOrWallet) return false;

    return true;
}

/**
 * Generates the display text for a fare estimate.
 * Fixed fare (i.e. Price Guarantee) is an exact amount, while metered rate is a range.
 */
export function GetFareDisplayText(fare: VehicleFareDetail, isFixedFare: boolean, doesPaymentMethodSupportPgFee: boolean) {

    let isFareRangeV2Enabled = appstore.getState().features.EnabledPreviews[PreviewFeatureId.FareRangeV2];

    const amountInDollars = CalculateExactAmountUserPaysInDollars(fare, isFixedFare, doesPaymentMethodSupportPgFee);

    if (isFixedFare) {
        // display price to exact cent
        return '$' + amountInDollars.toFixed(2);
    }

    if (isFareRangeV2Enabled) {
        let minDollars = Math.floor(amountInDollars * 0.95);
        let maxDollars = Math.ceil(amountInDollars * 1.15);

        return `$${minDollars} - $${maxDollars}`;
    }
    else {
        // display +- 10% range in dollars
        let minDollars = Math.floor(amountInDollars * 0.9);
        let maxDollars = Math.ceil(amountInDollars * 1.1);

        return `$${minDollars} - $${maxDollars}`;
    }    
}

/** Calculate the exact amount (in dollars) charged by the user. */
export function CalculateExactAmountUserPaysInDollars(fare: VehicleFareDetail, isFixedFare: boolean, doesPaymentMethodSupportPgFee: boolean) {
    let exactAmountCents = fare.FixedFareBreakdown.TotalAmount;
    let isFareRangeV2Enabled = appstore.getState().features.EnabledPreviews[PreviewFeatureId.FareRangeV2];

    if (ShouldRemovePgFee(isFixedFare, doesPaymentMethodSupportPgFee, !!isFareRangeV2Enabled)) {
        exactAmountCents -= fare.FixedFareBreakdown.PgFeeCents;
    }

    return exactAmountCents * 0.01;
}

/**
 * Decides whether to remove PgFee when displaying Fare Estimate or Fixed Fare in the UI.
 */
function ShouldRemovePgFee(isFixedFare: boolean, doesPaymentMethodSupportPgFee: boolean, isFareRangeV2Enabled: boolean): boolean {

    // PgFee should be removed from all the fares (Fixed/Metered) if payment method doesn't support PgFee. e.g: Account bookings
    if (!doesPaymentMethodSupportPgFee) return true;

    // if payment method supports PgFee, all the fixed fare bookings should have PgFee.
    if (isFixedFare) return false;

    // V2 fare range is calculated so that the lower bound of the range is closer to the PG value, hence PgFee is not removed when calculating meter fare.
    if (isFareRangeV2Enabled) return false;

    // normal meter fare (V1 fare range calculation)
    return true;
}

// Check is valid pickup and destination address 
export function IsValidPickupAndDestinationAddress(state: ApplicationState): boolean {
    return !!state.booking.Pickup.Address && !!state.booking.Dropoff.Address;
}