import { IconButton, InputAdornment, OutlinedInputProps, TextField, Tooltip } from "@mui/material";
import Close from "@mui/icons-material/Close";
import React from "react";
import PhoneInput, { CountryData } from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css';
import { Config } from "../../Config/Config";
import { GetValues } from "../../Config/MyAppConfig";
import { EditorState } from "../UILogicControl/UILogicControlEntities";
import { CustomErrorMessages } from "../Utils/ErrorMessages";
import { CheckPhone } from "./CheckPhone";
import { FullPhoneNumber } from "./PhoneNumberEntities";
import '../Booking/Widget/BookingContactNumber.scss';
import ErrorIcon from '@mui/icons-material/Error';

/** 
 *  Reusable phone number input control with separate country code flag selector.
 *  We use `react-intl-tel-input` for the flag selector, but a regular (Material-themed) text field for the local number part.
 *  The component supports hybrid controlled + uncontrolled input.
 */
export const PhoneNumberInput: React.FC<JsxProps> = (props) => {

    // #region Hooks

    const preferredCountryCodesIso2 = GetValues().AllowedCountryCodes;
    const defaultCountryCodeIso2 = preferredCountryCodesIso2[0];

    // whether the <input> element has input focus. Weakens the validation requirement
    const [isInputFocused, setIsInputFocused] = React.useState(false);

    // raw text typed by the user; the local number part
    const [inputText, setInputText] = React.useState('');

    // two letter country code (ISO 3166) for the selected item in the dropdown
    // Stored as upper case.
    const [countryCodeIso2, setCountryCodeIso2] = React.useState(defaultCountryCodeIso2);

    // the inner <input>. For when we need to force the text value
    const textInputRef = React.useRef<HTMLInputElement>(null);

    // instance of the PhoneInput component. Used for controlled input changes.
    const phoneInputRef = React.useRef<PhoneInput>(null);

    // controlled input
    React.useEffect(OnValuePropChanged, [props.Value]);

    React.useEffect(ConsiderSendingEditorState, [props.IsOptional, props.MobilesOnly, props.Value, isInputFocused, inputText, countryCodeIso2]);

    // #endregion

    // #region Render

    const errorMessage = GetDisplayedErrorMessage();
    const hasError = !!errorMessage;
    const maybeErrorBorder = hasError ? "contact-number-error" : "";

    return (
        <div className="booking-fields-panel contact-number-panel">
            <div className="contact-number-container">
                <div className={`phone-number-group ${maybeErrorBorder}`}>
                    <div className="country-code-section">
                        <PhoneInput
                            defaultCountry={preferredCountryCodesIso2[0]}
                            separateDialCode={true}
                            fieldId={"phoneInput"}
                            onSelectFlag={OnCountrySelected}
                            preferredCountries={preferredCountryCodesIso2}
                            ref={phoneInputRef}
                        />
                    </div>
                </div>
                <TextField
                    type="tel"
                    fullWidth={true}
                    label={props.LabelText}
                    variant="outlined"
                    value={inputText}
                    error={hasError}
                    inputRef={textInputRef}
                    className="simple-textfield"
                    onChange={OnTextInputChanged}
                    onBlur={() => OnInputFocusChange(false)}
                    onFocus={() => OnInputFocusChange(true)}
                    inputProps={{ maxLength: Config.ContactNumberMaxLength }}
                    InputProps={GetInputAdornments(hasError)}
                />
            </div>
            {hasError && <div className="booking-form-error-message">{errorMessage}</div>}
        </div>
    );

    // #endregion

    // #region Render Helpers

    /** 
     *  Returns the error message to actually display on the UI.
     *  Sometimes the valid is invalid, but we won't actually display an error, e.g. if the user is in the middle of editing the control.
     */
    function GetDisplayedErrorMessage(): string | null {

        const uiState = GetFullEditorState();

        // error suppression cases
        if (uiState.IsEditInProgress) return null;
        if (uiState.IsEmpty && !props.StrictValidation) return null;

        // normal cases
        return uiState.ErrorMessage;
    }

    /** 
     *  Returns a complete description of an intermediate editor state.
     */
    function GetFullEditorState(): EditorState {

        const errorMessage = GetRawValidationError();
        const isValid = !errorMessage;

        return {
            IsEditInProgress: isInputFocused,
            IsEmpty: inputText === '',
            IsValid: isValid,
            ErrorMessage: errorMessage,
        };
    }

    /** 
     *  Error message (or null) based on the current editor value.
     *  This may differ from the displayed error message.
     */
    function GetRawValidationError(): string | null {

        // empty cases
        if (inputText === '') {
            return props.IsOptional ? null : "Please enter a phone number";
        }

        // parse
        const result = CheckPhone.FromParts(countryCodeIso2, inputText);
        if (!result.IsValid) return result.Error;

        // mobile check
        if (props.MobilesOnly && !result.Value.IsMobile) {

            return CustomErrorMessages.PhoneNeedsMobile;
        }

        // all good
        return null;
    }

    /**
     * The icons to render at the start and end of the input text control.
     * This control does just clear. There is a bit of copy paste here. 
     */
    function GetInputAdornments(hasError: boolean): Partial<OutlinedInputProps> {

        const inputCustomisation: Partial<OutlinedInputProps> = {};

        inputCustomisation.endAdornment = (
            <InputAdornment position="end">
                {inputText !== '' &&
                    <Tooltip title="Clear" arrow>
                        <IconButton onClick={OnClearClicked} size="large">
                            <Close fontSize="small" />
                        </IconButton>
                    </Tooltip>
                }
                {
                    hasError && <ErrorIcon fontSize="small" color="error" />
                }
            </InputAdornment>
        );

        return inputCustomisation;
    }

    // #endregion

    // #region Controlled Input

    /** 
     *  This is called when the Value prop changes. This either means we have an input being pushed in from the app, OR it is a round trip of a UI value change.
     *  In the latter case, we want to avoid doing anything.
     */
    function OnValuePropChanged() {

        if (!props.Value) {

            // clear. (the flag doesn't have a natural clear action)
            ForceTextValue("");
        }
        else {

            // specific phone number
            ForceTextValue(props.Value.LocalPart);
            ForceCountry(props.Value.CountryCodeIso2.toUpperCase());
        }
    }

    /**
     * Push a value into the text box, if it's not there already. Also sets the state.
     */
    function ForceTextValue(text: string) {

        if (inputText != text) {
            setInputText(text);

            const textBox = textInputRef.current;

            if (textBox) {
                textBox.value = text;
            }
        }
    }

    /**
     * Push a value into the country (flag) dropdown.
     * The input value is the ISO 3166 two letter code, i.e. "AU", upper case.
     */
    function ForceCountry(newCountryCodeIso2: string) {

        // country / flag
        if (countryCodeIso2 != newCountryCodeIso2) {
            setCountryCodeIso2(newCountryCodeIso2);

            const input = phoneInputRef.current;

            if (input) {
                // react-intl-tel-input requires lower case
                input.setFlag(newCountryCodeIso2.toLowerCase());
            }
        }
    }

    // #endregion

    // #region UI Event Handlers

    /**
     * Keep track of input focus; it affects whether validation applies.
     */
    function OnInputFocusChange(newFocusState: boolean) {
        setIsInputFocused(newFocusState);
    }

    /**
     * Change event for the local phone number text.
     * Block non-numeric characters.
     */
    function OnTextInputChanged(e: React.ChangeEvent<HTMLInputElement>) {

        const value = e.target.value;

        if (!/^[0-9\b]*$/.test(value)) {
            e.preventDefault();
            return;
        }

        setInputText(value);
        ConsiderSendingValue(countryCodeIso2, value);
    }

    /** The user clicks the clear button in the local phone number text box. */
    function OnClearClicked() {

        ForceTextValue("");
        ConsiderSendingValue(countryCodeIso2, "");
    }

    /**
     * The user picks a country (flag) from the dropdown list.
     */
    function OnCountrySelected(currentNumber: string, newCountry: CountryData) {

        const newCountryIso2 = newCountry.iso2.toUpperCase();

        setCountryCodeIso2(newCountryIso2);
        ConsiderSendingValue(newCountryIso2, inputText);
    }

    // #endregion

    // #region Callbacks

    /** 
     *  Output the current UI to the callback prop if it's valid.
     *  Pass any new values (e.g. from event handlers) in these parameters, otherwise use the state variables with the same name.
     *  This is because the local state variable won't update until the next loop.
     */
    function ConsiderSendingValue(countryCodeIso2: string, inputText: string) {

        if (inputText == "") {
            props.OnValueChanged(null);
        }

        // parse
        const result = CheckPhone.FromParts(countryCodeIso2, inputText);
        if (!result.IsValid) return;

        // mobile check
        if (props.MobilesOnly && !result.Value.IsMobile) return;

        // OK!
        props.OnValueChanged(result.Value);
    }

    /** Report the current UI editor state to the callback prop. */
    function ConsiderSendingEditorState() {

        if (!props.OnEditorStateChanged) return;

        const state = GetFullEditorState();
        props.OnEditorStateChanged(state);
    }

    // #endregion
}

interface JsxProps {

    /** A specific value to be displayed. Use this prop for controlled input, i.e. when code wants to force a value into the UI. */
    Value: FullPhoneNumber | null;

    /** Event handler to receive new values from the UI. */
    OnValueChanged: (phoneNumber: FullPhoneNumber | null) => void;

    /** Low level UI state (focused, empty, valid, etc). Optional. */
    OnEditorStateChanged?: (newState: EditorState) => void;

    /** Material UI label on the text input for the phone number */
    LabelText: string;

    /** 
     *  Whether to displays an error state for empty input.
     *  Normally this starts false, but will become true when the user tries to submit the related form.
     */
    StrictValidation: boolean;

    /** When true, this field is allowed to be empty. */
    IsOptional: boolean;

    /** Only allow mobile numbers. For SMS scenarios. */
    MobilesOnly?: boolean;
}