import { AccountBookingPayload, BookingLocationContact, PickupServiceCheckState, ServiceCheckStatus } from "../BookingEntities";
import { PaymentOption } from "../../Payment/PaymentEntities";
import { AddressV2 } from "../../../Services/MakeBookingContracts";
import { BookingTimeV2 } from "../../BookingV2/BookingV2Entities";
import { DeliveryOptionKind } from "../Parcel/ParcelEntities";
import { AddressPointDetails } from "../../../Services/AddressPointsContracts";

/** Shared application state for the booking under construction in the create booking UI */
export interface BookingWorkFlowState {
    PickupServiceCheck: PickupServiceCheckState;
    PaymentOption?: PaymentOption;

    /** When true, adds a debug UI component to show the internal loading status */
    ShowDiagnosticUI: boolean;

    /** Whether the User has selected to make the booking on Account. When true, additional booking input fields are available */
    IsOnAccount: boolean;

    /** Account specific information only for bookings with accounts. */
    AccountData: AccountBookingPayload | null;

    /** It is user's device information(used for fraud detection), it is set using braintree library,it is a required field to successfully do an app payment
     * Booking api decides whether it is valid card booking by checking existence of device data field.
     * MPS also sends this devicedata to braintree along with transaction request,braintree uses kount for fraud detection.
    */
    DeviceData?: string;

    /** Template name text for favourites */
    TemplateName?: string;

    /** Booking timing details to use for V2 booking service. */
    BookingTimeV2: BookingTimeV2;

    /** user selected delivery option when parcel widget booking  */
    DeliveryOption: DeliveryOptionKind | null;

    /** Delivery option error */
    DeliveryOptionError: string | null;

    /** All the booking details for the pickup location */
    Pickup: BookingLocationInfo;

    /** All the booking details for the dropoff location */
    Dropoff: BookingLocationInfo;

    /**
     * Trip ID associated with any Google Maps API calls for the booking being created.
     * This supports the per-trip billing mechanism in Google ODRD.
     * Format: a GUID.
     * It's typed as nullable because it is briefly null at startup until it is first loaded from the server. Gotta be careful.
     */
    GoogleOdrdTripId: string | null;
}

/** All the data related to a location (pickup / stopover / dropoff) of a booking that is being constructed in the booking widget. */
export interface BookingLocationInfo {

    /** Contact person name and phone number for this location. */
    Contact: BookingLocationContact;

    /** per-location driver notes */
    DriverNotes: string | null;

    /** Address geopoint, place id, and other metadata for this location */
    Address: AddressV2 | null;

    /** The Favourite Address record matching [Address], if the user selected the address by picking a favourite. */
    Favourite: AddressPointDetails | null;
}

/** Initial value of additional address details store slices. */
const contactInitialValue: BookingLocationContact = {
    Phone: null
};

const locationInitialValue: BookingLocationInfo = {
    Contact: contactInitialValue,
    DriverNotes: null,
    Address: null,
    Favourite: null,
};

/** Initial value of the Booking state */
export const DefaultBookingWorkFlowState: BookingWorkFlowState = {
    PickupServiceCheck: {
        status: ServiceCheckStatus.NoInputSelected
    },
    ShowDiagnosticUI: false,
    IsOnAccount: false,
    AccountData: null,
    BookingTimeV2: {
        IsImmediate: true
    },

    DeliveryOption: null,
    DeliveryOptionError: null,

    Pickup: locationInitialValue,
    Dropoff: locationInitialValue,
    GoogleOdrdTripId: null, // populated during startup
};