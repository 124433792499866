import { ApplePayPayload, GooglePaymentTokenizePayload, ThreeDSecureVerifyPayload } from "braintree-web";
import { PayPalTokenizePayload } from "braintree-web/paypal";

/** 
 * Redux store "GuestPayment" state slice.
 * Data for guest payment flows like PayPal.
 */
export interface GuestPaymentState {

    /** 
     * Tokenised representation of a PayPal Payment method.
     * Temporarily stored during the PayPal guest UI flow: after the PayPal SDK interaction to load the user's PayPal details but before SMS verification and guest user creation.
     */
    PayPalTokenised: PayPalTokenizePayload | null;

    /** The temporary user account created after a complete guest payment flow. */
    BackingUser: BackingUserDetail | null;

    /**
     * Tokenised representation of a Apple Pay payment method.
     * Temporarily stored during the Apple Pay guest UI flow: after the Apple Pay SDK interaction to load the user's Apple Pay details but before SMS verification and guest user creation.
     */
    ApplePayTokenised: ApplePayPayload | null;

    /**
     * Tokenised representation of a Google Pay payment method.
     * Temporarily stored during the Google Pay guest UI flow: after the Google Pay SDK interaction to load the user's Google Pay details but before SMS verification and guest user creation.
     */
    GooglePayTokenised: GooglePaymentTokenizePayload | null;

    /**
     * Tokenised representation of a 3DS verified credit/debit card.
     * Temporarily stored during the guest credit card UI flow: after the 3DS SDK interaction to verify the card but before SMS verification and guest user creation.
     */
    CreditCard3DsVerified: ThreeDSecureVerifyPayload | null
}

/** Initial value of the GuestPayment Redux state slice */
export const GuestPaymentInitialState: GuestPaymentState = {
    PayPalTokenised: null,
    BackingUser: null,
    ApplePayTokenised: null,
    GooglePayTokenised: null,
    CreditCard3DsVerified: null,
}

/** A temporary user account supporting Guest Payment flows. */
export interface BackingUserDetail {

    /** HTTP Authorisation for API calls to act as the temporary user. */
    Auth: string;

    /** The phone number used to create the temporary user. E.164 with leading plus. */
    PhoneNumber: string;
}