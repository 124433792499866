import React from "react";
import { connect } from 'react-redux';
import "./BookingButton.scss";
import { ApplicationState } from "../../../appState";
import { BookingWidgetModeKind } from '../../BookingTemplate/BookingTemplateEntities';
import { BookingProps, BookingPropsV2 } from "../BookingProps";
import { AreBookingRequisitesReady } from "../../UILogicControl/UILogicControlHelper";
import { CreateBookingTemplate, ValidateBookingTemplateInput } from '../../BookingTemplate/BookingTemplateHelper';
import { Dispatch } from "../../Dispatch";
import { DialogKind } from "../../Dialog/DialogEntities";
import { WellKnownMessageKind, DescriptiveErrorMessages, CustomErrorMessages } from "../../Utils/ErrorMessages";
import { LogEvent } from '../../../utils/LogEvent';
import { PaymentCardErrorType } from "../../Payment/PaymentEntities";
import { ShowDialogSimpleErrorMessage } from "../../../widgets/general-error-message/ErrorMessagingHelper";
import { IsVerificationNeeded } from "../../Authentication/AuthHelper";
import { VerifyPhoneForProfile } from "../../Verification/VerifyPhoneForProfile";
import { CreateBookingWithoutSmsVerification } from "../CreateBookingCommon";
import { ValidateGuestUser } from '../../Verification/VerifyPhoneForBooking';
import { HasLogInProperly } from "../../Authentication/AuthHelper";
import { IsFixedPriceBooking, IsPaymentAndServiceValidForPriceGuarantee } from "../../Payment/PaymentHandler";
import { CheckForCardExpiry } from "../../Payment/PaymentCardValidation";
import appstore from "../../../appStore";
import { OptionalUI } from "../OptionalParts/OptionalUI";
import { ApplePay } from "../../ApplePay/ApplePay";
import { GooglePay } from "../../GooglePay/GooglePay";
import { ThreeDSecure } from "../../ThreeDSecure/ThreeDSecure";
import { GuestCreditCard } from "../../CreditCard/GuestCreditCard";

/**
 * Booking button with styles and behaviour
 */
export class BookingButton extends React.Component<BookingProps & BookingPropsV2> { 
    
    constructor(props: BookingProps & BookingPropsV2) {
        super(props);
    }

    onBookButtonClicked = async () => {
        const userProfile = this.props.Authentication.UserProfile;

        // If signed in user does not have a contact number, immediately fail the booking and show an error message.
        if (userProfile && !userProfile.ContactPhone) {
            Dispatch.Dialog.SetDescriptiveErrorMessage({ ...DescriptiveErrorMessages.CreateBookingFailedWithoutRetry });
            Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
            return;
        }

        Dispatch.UILogicControl.OnIsStrictValidationModeOnBookingFormChange(true);

        // Testing V2 can guarantee V1, if FeatureFlags.BookingApiV2 === false
        if (this.props.BookingTimeV2.IsImmediate)
        {
            LogEvent.BookForNowTriggered();
        } 
        else {
            LogEvent.BookForFutureTriggered();
        }

        const isUserLoggedIn = HasLogInProperly(true);

        const isBookingOnAccount = isUserLoggedIn && this.props.SelectedAccountData;

        // Display error message for invalid payment card when not booking on accounts
        if (!isBookingOnAccount && !this.props.PaymentOption) {
            Dispatch.Payment.CardErrorMessage(PaymentCardErrorType.CardNotSelected);
        }

        // Payment card is mandatory for price guarantee except for Parcel
        if (!this.props.IsServiceAndPaymentMethodValidForPriceGuarantee) {
            Dispatch.Payment.CardErrorMessage(PaymentCardErrorType.CardNotProvidedForPriceGuarantee);
        }

        // Apple Pay is selected but can't pay for the trip with Apple Pay. e.g: Apple Pay is selected while the Fixed fare toggle is on, then turn the toggle off and click on book button.
        if (this.props.PaymentOption?.Type == "ApplePay" && !IsFixedPriceBooking()) Dispatch.Payment.CardErrorMessage(PaymentCardErrorType.ApplePayWithFixedPriceOnly);
        if (this.props.PaymentOption?.Type == "GooglePay" && !IsFixedPriceBooking()) Dispatch.Payment.CardErrorMessage(PaymentCardErrorType.GooglePayWithFixedPriceOnly);

        // Check if the selected payment card expired (if selected)
        if (this.props.PaymentOption) CheckForCardExpiry(this.props.PaymentOption);

        // Trigger error message for invalid delivery option
        const appState = appstore.getState();

        if (OptionalUI.DeliveryOption(appState) && (this.props.DeliveryOption === null)) {
            Dispatch.Booking.SetDeliveryOptionError(CustomErrorMessages.ParcelNoDeliveryOption);
        }

        if (!AreBookingRequisitesReady()) {
            LogEvent.InvalidBookingInputs();
            ShowDialogSimpleErrorMessage(WellKnownMessageKind.BookingStrictValidation); 
        }
        else {
            LogEvent.BookingTriggered();           

            /**
             * Validation:
             * 1> Guest booking with mobile -> go to verification [SMS + tracking];
             * 2> Guest booking wtih landline -> create booking directly [tracking];
             * 3> For logged-in users, all validations are done/handled by "logged-in users validation process", such as:
             *    (a) No contact number;
             *    (b) Contact number is invalid;
             *    (c) Valid mobile but not verified;
             *    Users will be forced to logout, if they decline.
             */             
            if (isUserLoggedIn) {
                if (IsVerificationNeeded(userProfile!)) {
                    VerifyPhoneForProfile(userProfile!, true);
                }
                else {
                    if (this.props.PaymentOption?.Type === "ApplePay") await ApplePay.RunApplePayUiFlowAndProceedBooking();
                    else if (this.props.PaymentOption?.Type === "GooglePay") await GooglePay.RunGooglePayUiFlowAndProceedBooking();                    
                    else if (OptionalUI.ThreeDSecureVerification(appState) && this.props.PaymentOption?.Card?.CheckFor3Ds && this.props.PaymentOption?.Type !== "PayPal") await ThreeDSecure.Run3DsVerificationAndProceedBooking();
                    else CreateBookingWithoutSmsVerification();
                }                
            }
            else if (appState.GuestPayment.BackingUser) {
                CreateBookingWithoutSmsVerification();
            }
            else {
                if (this.props.PaymentOption?.Type === "ApplePay") await ApplePay.RunApplePayUiFlowAndProceedBooking();
                else if (this.props.PaymentOption?.Type === "GooglePay") await GooglePay.RunGooglePayUiFlowAndProceedBooking();
                else if (this.props.PaymentOption?.Type === "CreditOrDebit") await GuestCreditCard.RegisterCreditCardAndProceedBooking();
                else ValidateGuestUser();
            }
        }
    }

    onSaveFavouriteButtonClicked = () => {
        
        // Display error dialog for invalid template input
        if (!ValidateBookingTemplateInput()) {

            Dispatch.UILogicControl.OnIsStrictValidationModeOnBookingFormChange(true);
            
            ShowDialogSimpleErrorMessage(WellKnownMessageKind.BookingStrictValidation);

            LogEvent.InvalidCreatingTemplateInputs();
            
            return;
        }
        
        // Save the booking template
        CreateBookingTemplate();
    }

    /** 
     *  Returns true if the Delivery Option is valid.
     *  The option is mandatory, but only when it is actually displayed.
     */
    CheckDeliveryOption: () => boolean = () => {

        return true;
    }

    render() {
        const IsTemplateModeOn = this.props.BookingWidgetMode !== BookingWidgetModeKind.Booking;  
        const btnFavStyle = ValidateBookingTemplateInput() ? "book-btn-ready" : "book-btn-silent";
        const btnStyle = AreBookingRequisitesReady() ? "book-btn-ready" : "book-btn-silent";
         
        return (
            <div className="booking-btn-panel">
                {IsTemplateModeOn ? <button className={btnFavStyle} onClick={this.onSaveFavouriteButtonClicked}>Save favourite</button>
                    : <button className={btnStyle} onClick={this.onBookButtonClicked}>Request booking</button>}
            </div> 
        );
    }
}

/**
 * Very important to subscribe below, even they are not used directly within this component:
 * 1> Passenger;
 * 2> PickupServiceCheck;
 * 3> BookingTime;
 * 4> Pickup;
 * 5> Dropoff;
 * They are critical to be subscribe for a proper function of strict validation.
 */
function mapStateToProps(state: ApplicationState): BookingProps & BookingPropsV2 {
    return {
        IsVehicleSelectionActive: state.condition.IsVehicleSelectionActive,
        PickupServiceCheck: state.booking.PickupServiceCheck,
        ConditionLoadState: state.condition.LoadingStatus,
        IsBookingFormStrictValidationModeOn: state.uiLogicControl.BookingForm.IsStrictValidationModeOn,
        Condition: state.condition,
        Authentication: state.authentication,
        Verification: state.verification,
        BookingFormApiRunningStatus: state.uiLogicControl.BookingForm.ApiRunningStatus,
        BookOnAccount: state.booking.IsOnAccount,
        UserProfile: state.authentication.UserProfile,
        PaymentOption: state.booking.PaymentOption,
        SelectedAccountData: state.booking.AccountData,
        ContactNumber: state.verification.UserContactNumberInfo.Contactnumber,
        BookingTemplateName: state.booking.TemplateName,
        BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
        DoesPickupInputHaveValue: state.uiLogicControl.AddressStatus.Pickup.DoesInputHaveValue,
        DoesDropoffInputHaveValue: state.uiLogicControl.AddressStatus.Dropoff.DoesInputHaveValue,
        IsSelectedVehicleServiceable: state.uiLogicControl.BookingForm.IsSelectedVehicleServiceable,
        SelectedCondition: state.condition.SelectedCondition,
        BookingTimeV2: state.booking.BookingTimeV2,
        PickupV2: state.booking.Pickup.Address,
        DropoffV2: state.booking.Dropoff.Address,
        IsServiceAndPaymentMethodValidForPriceGuarantee: IsPaymentAndServiceValidForPriceGuarantee(state),
        ActiveBookingForm: state.uiLogicControl.BookingForm.ActiveBookingForm,
        DeliveryOption: state.booking.DeliveryOption
    };
}

export default connect(mapStateToProps)(BookingButton);